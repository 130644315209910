<template>
    <div class="card">
        <div class="organization">
            <span :key="assessment?.organization?.logo" class="avatar">
                <a-avatar 
                    :size="30"
                    :src="assessment?.organization?.logo"
                    icon="fi-rr-users-alt" 
                    flaticon />
            </span>
            <span class="name">
                {{ assessment?.organization?.name }}
            </span>
        </div>
        <div class="number">
            {{ assessment.issue.number }}
        </div>
        <div class="total-value">
            <div class="circle" :class="!assessment.total_value && 'circle-border'" :style="{ backgroundColor: circleColor }">
                <span>{{ assessment.total_value }}</span>
            </div>
        </div>

        <div class="issue_date">
            {{ this.$moment(assessment.issue.issue_date).format('DD MMMM YYYY') }}
        </div>
        <div class="issue-status">
            <a-tag :color="assessment.status.color">
                {{ assessment.status.name }}
            </a-tag>
        </div>
        <div class="buttons">
            <div class="delete-button">
                <a-button
                    v-if="deleteAvailability"
                    type="ui"
                    shape="circle"
                    @click="deleteAssessment()"
                    ghost
                    class="flex items-center justify-center">
                    <i class="fi fi-rr-trash text-red-500"></i>
                </a-button>
            </div>
            <div class="edit-button">
                <a-button
                    v-if="editAvailability"
                    type="ui" 
                    shape="circle"
                    @click="editAssessment()"
                    ghost
                    class="flex items-center justify-center">
                    <i class="fi fi-rr-edit"></i>
                </a-button>
            </div>
        </div>
        <div 
            class="expand-button"
            :class="isExpand && 'rotate-90'"
            @click="openAssessmentis()">
            <a-button
                type="link">
                <i class="fi fi-rr-angle-right"></i>
            </a-button>
        </div>
        <div class="item_body" v-show="isExpand">
            <a-spin :spinning="assessmentLoading" class/>
            <template v-if="assessmentDetail">
                <div class="summary">
                    <div class="font-semibold">{{ $t('inquiries.summary') }}:</div>
                    <template v-if="assessmentDetail?.issue?.summary">
                        <div>{{ assessmentDetail?.issue?.summary }}</div>
                    </template>
                    <template v-else>
                        <span class="no-data">{{ $t('inquiries.noData') }}</span>
                    </template>
                </div>
                <div class="recipient">
                    <div class="font-semibold">{{ $t('inquiries.sentTo') }}: </div>
                    <div>{{ getRecipient }}</div>
                </div>
                <div class="inquir_text">
                    <div class="font-semibold">{{ $t('inquiries.inquiryText') }}:</div>
                    <template v-if="assessmentDetail?.issue?.text">
                        <TextViewer
                            :body="assessmentDetail.issue.text"/>
                    </template>
                    <template v-else>
                        <span class="no-data">{{ $t('inquiries.noData') }}</span>
                    </template>
                </div>
                <div class="criterions">
                    <div class="font-semibold">{{ $t('inquiries.evaluationCriteria') }}:</div>
                    <div v-for="item in assessmentDetail.risk_assessment_criteria" :key="item.criteria.id" class="risk_assessment_criteria_list">
                        <div class="item" :class="item.value === 1 && 'item-is-on'">
                            {{ item.criteria.name }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="empty">
                <a-empty :description="false" />
            </template>
        </div>
    </div>
</template>
  
<script>
import TextViewer from '@apps/CKEditor/TextViewer.vue'
import eventBus from '@/utils/eventBus'

export default {
    name: 'RiskAssessment',
    components: {
        TextViewer,
    },
    props: {
        assessment: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isExpand: false,
            assessmentLoading: false,
            assessmentDetail: null,
            empty: false
        };
    },
    computed: {
        circleColor() {
            if (this.assessment.total_value >= 1 && this.assessment.total_value <= 2) {
                return 'yellow'
            } else if (this.assessment.total_value >= 3 && this.assessment.total_value <= 5) {
                return 'orange'
            } else if (this.assessment.total_value >= 5) {
                return 'red'
            } else {
                return 'white'
            }
        },
        getRecipient() {
            const recipient = this.assessmentDetail.sent_for === 1 ? this.$t('inquiries.head_of_apparatus') : this.$t('inquiries.main_leader_or_deputies')
            return recipient
        },
        deleteAvailability() {
            if('delete' in this.assessment && 'availability' in this.assessment.delete) {
                return this.assessment.delete['availability']
            } else {
                return false
            }
        },
        editAvailability() {
            if('edit' in this.assessment && 'availability' in this.assessment.edit) {
                return this.assessment.edit['availability']
            } else {
                return false
            }
        }
    },
    created() {
        eventBus.$on('update_assessment_details', id => {
            if(this.assessment.id === id) {
                this.getAssessmentDetail()
            }
        })
    },
    beforeDestroy() {
        eventBus.$off('update_assessment_details')
    },
    methods: {
        async openAssessmentis() {
            this.isExpand = !this.isExpand
            if(this.isExpand) {
                await this.getAssessmentDetail()
            } else {
                this.assessmentDetail = null
            }
        },
        editAssessment() {
            eventBus.$emit('edit_inquir', this.assessment.id)
        },
        deleteAssessment() {
            this.$confirm({
                title: this.$t('inquiries.confirm_delete_title'),
                content: '',
                okText: 'Удалить',
                okType: 'danger',
                zIndex: 2000,
                closable: true,
                cancelText: 'Закрыть',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/table_actions/update_is_active/', {
                            id: this.assessment.id,
                            is_active: false
                        })
                            .then(() => {
                                eventBus.$emit('assessment_list_reload')
                                this.$message.success(this.$t('inquiries.delete_success'))
                                resolve()
                            })
                            .catch(e => {
                                console.log(e)
                                reject(e)
                            })
                    })
                }
            })
        },
        async getAssessmentDetail() {
            if(!this.assessmentLoading) {
                try {
                    this.assessmentLoading = true
                    const { data } = await this.$http.get(`risk_assessment/${this.assessment.id}/`)
                    if(data) {
                        this.assessmentDetail = data
                    }
                } catch(e) {
                    console.log(e)
                    this.empty = true
                } finally {
                    this.assessmentLoading = false
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.card{
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    row-gap: 5px;
    column-gap: 5px;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    .organization{
        grid-column: span 2;
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        .avatar{
            padding-right: 8px;
        }
        .name{
            font-weight: 600;
        }
    }
    .assessment-type{
        align-self: center;
    }
    .total-value{
        justify-self: end;
        .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            color: black;
        }
        .circle-border{
            border: var(--bgColor6) solid 1px; 
        }
    }
    .number{
        align-self: center;
    } 
    .issue-status{
        min-width: 90px;
        justify-self: end;
        min-width: 0;
        .ant-tag{
            margin-right: 0;
        }
    }
    .buttons{
        display: grid;
        grid-template-columns: repeat(2, auto);
        width: fit-content;
        .delete-button{
            min-width: 32px;
        }
        .edit-button{
            min-width: 32px;
        }
    }
    .expand-button{
        justify-self: end;
        align-self: end;
        min-width: 0;
    }
    .rotate-90{
        transform: rotate(90deg);
    }
    .item_body {
        grid-column: span 2;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
        border-top: var(--bgColor6) solid 1px;
        padding-top: 5px;
        .summary{}
        .recipient{}
        .inquir_text{}
        .criterions{
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 5px;
            .item{
                border: 1px solid rgb(235, 235, 235, 1);
                border-radius: 8px;
                opacity: 0.3;
            }
            .item-is-on{
                opacity: 1;
            }
        }
        .no-data{
            opacity: 0.3;
        }
    }
    

}
</style>